<template>
  <div class="hero">
    <div class="hero-head">
      <nav class="navbar">
        <div class="navbar-menu">
          <div class="navbar-start">
            <router-link class="navbar-item" :to="{ name: 'volume', params: {volumeId: volume.id} }" v-if="routeName != 'volume' && volume">
              <fa-icon :icon="['fas', 'chevron-left']" />
              <span class="ml-2">目次へ</span>
            </router-link>
          </div>
          <div class="navbar-end">
            <user-nav-item />
          </div>
        </div>
      </nav>
    </div>

    <div class="hero-body">
      <div class="container has-text-centered">
        <img src="../assets/image/logo.png" width="300" style="max-width: 70%;">
        <p class="title is-4 mt-3" v-if="volume">{{ volume.title }}</p>
      </div>
    </div>
  </div>
</template>


<script>
import UserNavItem from '@/components/UserNavItem'

export default {
  props: ['volume'],
  components: { UserNavItem },
  data() {
    return {
      routeName: this.$route.name
    }
  },
}
</script>


<style lang="scss" scoped>
  .hero-body {
    padding: 1rem 1.5rem 3rem 1.5rem;
  }
</style>
