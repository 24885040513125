<template>
  <div v-if="volume">
    <hero :volume="volume" />

    <div class="container p-5" style="max-width: 1000px;">
      <p class="title is-4">ダッシュボード</p>

      <div class="columns is-mobile is-multiline">
        <div class="column is-12">
          <div class="box">
            <div class="level is-mobile">
              <div class="level-left">
                <div class="level-item">
                  <p class="title is-5">アンケート</p>
                </div>  
              </div>
              <div class="level-righ">
                <div class="level-item">
                  <download-csv
                    :data="surveyResponseData"
                    :fields="surveyFields"
                    :labels="surveyLabels"
                    name="shiftomo_letter_survey_responses.csv"
                    class="button is-ghost"
                  >CSVダウンロード</download-csv>
                </div>
              </div>
            </div>
            <div>
              回答数：<b class="is-size-4">{{ surveyResponses.length }}</b>
              <span class="pl-3" v-if="surveyResponses[0]">({{ formattedDate(surveyResponses[0].createdAt) }})</span>
            </div>
          </div>
        </div>

        <div class="column is-12">
          <div class="box">
            <div class="level is-mobile">
              <div class="level-left">
                <div class="level-item">
                  <p class="title is-5">アナリティクス</p>
                </div>  
              </div>
              <div class="level-righ">
                <div class="level-item">
                  <download-csv
                    :data="contents"
                    :fields="Object.keys(contentLabels)"
                    :labels="contentLabels"
                    name="shiftomo_letter_contents.csv"
                    class="button is-ghost"
                  >CSVダウンロード</download-csv>
                </div>
              </div>
            </div>
            <table class="table is-fullwidth">
              <thead>
                <tr>
                  <th></th>
                  <th style="width: 80px;">ビュー</th>
                  <th style="width: 80px;">いいね</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="content in contents" :key="content.id">
                  <td>{{ content.title }}</td>
                  <td>{{ content.accessCount || 0 }}</td>
                  <td>{{ content.likeCount || 0 }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { db } from '@/main'
import Hero from '@/components/Hero'
import moment from 'moment'

export default {
  components: { Hero },
  data() {
    return {
      volumeId: this.$route.params.volumeId,
      volume: null,
      contents: null,
      surveyResponses: null,
      surveyLabels: {
        createdAt: "回答日時",
        respondent: "分類",
        name: "名前",
        relationship: "間柄",
        withWhom: "誰と",
        favorites: "良かったコンテンツ",
        understand: "SHIFTへの理解度",
        impression: "SHIFTへの印象",
        rating: "満足度",
        messages: "メッセージ",
        videoWatched: "動画を見たか",
        videoWatchedNote: "動画を見た感想",
        // stopSending: "郵送NG",
        // vol3ShiftgramWatched: "vol3.シフトグラムを見たか",
        // vol3ShiftgramImpression: "vol3.シフトグラムの感想",
        // vol3ShiftgramImpressionOther: "vol3.シフトグラムの感想(その他)",
        // vol3CredoImpression: "vol3.クレドの感想"
      },
      surveyFields: [
        "createdAt",
        "respondent",
        "name",
        "relationship",
        "withWhom",
        "favorites",
        "understand",
        "impression",
        "rating",
        "messages",
        "videoWatched",
        "videoWatchedNote",
        // "stopSending",
        // "vol3ShiftgramWatched",
        // "vol3ShiftgramImpression",
        // "vol3ShiftgramImpressionOther",
        // "vol3CredoImpression"
      ],
      contentLabels: { title: "タイトル", videoId: "動画ID", likeCount: "いいね数", accessCount: "アクセス数" },
    }
  },
  firestore() {
    const volumeRef = db.collection('volumes').doc(this.volumeId)

    return {
      volume: volumeRef,
      contents: volumeRef.collection('contents').orderBy('position', 'asc'),
      surveyResponses: volumeRef.collection('surveyResponses').orderBy('createdAt', 'desc'),
    }
  },
  methods: {
    formattedDate: function(timestamp) {
      return timestamp ? moment(timestamp.toDate()).format('YYYY-MM-DD HH:mm') : null
    }
  },
  computed: {
    surveyResponseData: function() {
      console.log(this.surveyResponses)
      return this.surveyResponses.map(row => {
        const buff = {}
        Object.keys(row).forEach((key) => {
          var val = row[key]
          if (key === 'createdAt' && val) {
            val = this.formattedDate(val)
          }
          buff[key] = val
        })
        return buff
      })
    }
  },
}
</script>


<style lang="scss">
</style>
